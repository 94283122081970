import React from "react";
import Reels from "./Reels";
import Fotos from "./Fotos";
import videos from "../videos";
import fotos from "../fotos";

 function createVideo(video) {
    return (
      <Reels
        key={video.id}
        vURL={video.vURL}
      />
    );
  }
  function createFoto(foto) {
    return (
      <Fotos
        key={foto.id}
        iURL={foto.iURL}
      />
    );
  }

function Marketing() {
    return (
        <section className="page-section bg-dark" id="marketing">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-lg-12 text-center">
                    {videos.map(createVideo)}
                    </div>
                </div>
            </div>
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-lg-12 text-center">
                    {fotos.map(createFoto)}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Marketing;