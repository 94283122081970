import React from "react";

function Producto(props) {
    return (
        <div className="col-lg-4 col-sm-6" style={{"border":"solid 10px"}}>
            <a className="portfolio-box" href="#concretos" title={props.name}>
                <img className="img-fluid" src={props.imgURL} alt="..." />
            </a>
        </div>
    );
}

export default Producto;