import Header from "./components/Header";
import Nav from "./components/Nav";
import About from "./components/About";
import Concreto from "./components/Concreto";
import Contact from "./components/Contact";
import Marketing from "./components/Marketing";
import Proyectos from "./components/Proyectos";
import Footer from "./components/Footer";

function App() {
  return (
    <>
    <Header />
    <Nav />
    <About />
    <Concreto />
    <Marketing />
    <Proyectos />
    <Contact />
    <Footer />
    </>
  );
}

export default App;
