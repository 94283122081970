import React from "react";

function Contact() {
    return (
    <section className="page-section bg-light text-dark" id="contact">
        <div className="container px-4 px-lg-5 text-center">
            <h2 className="mb-4">CONTÁCTANOS HOY</h2>
            <div className="row gx-4 gx-lg-5 justify-content-center">
                <div className="col-lg-4 text-center mb-5 mb-lg-0">
                    <i className="fs-2 mb-3 text-muted"></i>
                    <div>antonio_ulloa@grupocavi.com.mx</div>
                    <div>abelardo_amador@grupocavi.com.mx</div>
                    <br></br>
                    <i className="fs-2 mb-3 text-muted"></i>
                    <div>433 1031 1596 /  433 100 1103</div>
                </div>
            </div>
        </div>
    </section>
    );
}

export default Contact;