import React from "react";

function Fotos(props) {
    return (
        <img style={{
            "height": "160px", 
            "width": "240px",
            "border-radius": "10px",
            "margin": "5px 10px 5px 10px"
        }} src={props.iURL} alt=""/>
    );
}

export default Fotos;