import React from "react";

function About() {
    return (
        <section className="page-section bg-dark" id="about">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-lg-8 text-center">
                        <h2 className="text-white mt-0" style={{"letter-spacing":"0.5rem"}}>TENEMOS LO QUE NECESITAS</h2>
                        <p className="text-white-75 mb-4">Concreto premezclado para la industria de la construcción y particulares.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;