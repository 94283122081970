const concreto = [
    {
      id: 1,
      name: "Tiro directo",
      imgURL:
        "assets/img/directo.jpg"
    },
    {
      id: 2,
      name: "Tiro bombeado",
      imgURL:
        "assets/img/bombeado.jpg"
    }
      ,
    {
      id: 3,
      name: "Concavi",
      imgURL:
        "assets/img/2.jpg"
    }
  ];
  
  export default concreto;