const videos = [
    {id: 1,vURL: "assets/img/marketing/reels/reel1.mp4"},
    {id: 2,vURL: "assets/img/marketing/reels/reel2.mp4"},
    {id: 3,vURL: "assets/img/marketing/reels/reel3.mp4"},
    {id: 4,vURL: "assets/img/marketing/reels/reel4.mp4"},
    {id: 5,vURL: "assets/img/marketing/reels/reel5.mp4"},
    {id: 6,vURL: "assets/img/marketing/reels/reel6.mp4"},
    {id: 7,vURL: "assets/img/marketing/reels/reel7.mp4"},
    {id: 9,vURL: "assets/img/marketing/reels/reel9.mp4"},
    {id: 10,vURL: "assets/img/marketing/reels/reel10.mp4"},
    {id: 11,vURL: "assets/img/marketing/reels/reel11.mp4"},
    {id: 12,vURL: "assets/img/marketing/reels/reel12.mp4"},
    {id: 13,vURL: "assets/img/marketing/reels/reel13.mp4"},
    {id: 14,vURL: "assets/img/marketing/reels/reel14.mp4"},
    {id: 15,vURL: "assets/img/marketing/reels/reel15.mp4"},
    {id: 16,vURL: "assets/img/marketing/reels/reel16.mp4"},
    {id: 21,vURL: "assets/img/marketing/reels/reel21.mp4"},
    {id: 22,vURL: "assets/img/marketing/reels/reel22.mp4"},
    {id: 23,vURL: "assets/img/marketing/reels/reel23.mp4"},
    {id: 24,vURL: "assets/img/marketing/reels/reel24.mp4"},
    {id: 25,vURL: "assets/img/marketing/reels/reel25.mp4"},
    {id: 26,vURL: "assets/img/marketing/reels/reel26.mp4"},
    {id: 27,vURL: "assets/img/marketing/reels/reel27.mp4"}
  ];
  
  export default videos;