import React from "react";

function Reels(props) {
    return (
            <video style={{
                "height": "426px", 
                "width": "240px",
                "border-radius": "10px",
                "margin": "5px 10px 5px 10px"
            }} controls loop muted>
                <source src={props.vURL} type="video/mp4"/>
            </video>
    );
}

export default Reels;