import React from "react";
import Proyecto from "./Proyecto";
import proyectos from "../proyectos";

function createVideo(proyecto) {
    return (
      <Proyecto
        key={proyecto.id}
        vURL={proyecto.vURL}
      />
    );
  }

function Proyectos() {
    return (
        <section className="page-section bg-dark" id="proyectos">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-lg-12 text-center">
                    {proyectos.map(createVideo)}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Proyectos;